const div = document.getElementById('timetable-search-banner');
const title = div?.getAttribute('data-title');
const mapLink = div?.getAttribute('data-map-link');

const timetableSearchBannerConfig = {
  title: title ? decodeURI(title) : '',
  mapLink: mapLink ? decodeURI(mapLink) : '',
};

export default timetableSearchBannerConfig;
